<template>
  <div class="d-flex justify-content-between align-items-center">
    <div class="title flex-grow-1 d-flex align-items-start mb-3">
      <div>
        <h4 class="mb-0">{{ name }}</h4>
        <div v-if="master" class="small font-weight-bold text-warning">
          Added by EZ-AD Representative
        </div>
      </div>
      <div class="edit-buttons ml-3">
        <a href="#" class="remove-bt" @click.prevent="remove" aria-label="Remove">
          <svg width="14" height="16" xmlns="http://www.w3.org/2000/svg"><g transform="translate(-8 -7)" fill-rule="nonzero" fill="none"><circle fill="#E2E2E3" cx="15" cy="15" r="15"/><path d="M21 13v8c0 1.1-.9 2-2 2h-8c-1.1 0-2-.9-2-2v-8h12zm-3-6c.6 0 1 .4 1 1v2h3v2H8v-2h3V8c0-.6.4-1 1-1zm-1 2h-4v1h4V9z" fill="#90939A"/></g></svg>
        </a>
      </div>
      <div v-if="stores && stores.length > 1" class="mx-5" style="width: 400px;">
        <label>Show on Locations</label>
        <multiselect
          v-model="selectedLocations"
          :options="stores"
          :multiple="true"
          placeholder="All"
          label="name"
          @input="updateAssociatedLocations"
          track-by="id">
        </multiselect>
      </div>
    </div>
    <div class="d-flex flex-column align-items-end">
      <div class="custom-control custom-switch">
        <input type="checkbox" v-model="visible" class="custom-control-input" :id="`hidden-${id}`" @change="updateHidden">
        <label class="custom-control-label" :for="`hidden-${id}`">Visible</label>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'WidgetHeader',
  components: {
    Multiselect
  },
  props: {
    id: {
      default: null
    },
    name: {
      default: ''
    },
    hidden: {
      default: false
    },
    master: {
      default: false
    },
    associatedLocations: {
      default: null
    },
  },
  data() {
    return {
      visible: !this.hidden,
      selectedLocations: this.associatedLocations
    };
  },
  computed: {
    stores() {
      const st = this.$store.state.storeLocations || [];
      return st.map(e => {
        const { name, id } = {...e};
        return Object.assign({}, { id, name });
      });
    }
  },
  methods: {
    remove() {
      this.$emit('remove');
    },
    updateHidden() {
      this.$emit('updateHidden', !this.visible);
    },
    updateAssociatedLocations() {
      this.$emit('updateAssociatedLocations', this.selectedLocations);
    },
  }
};
</script>

<style scoped lang="scss">
  .delete-bt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 20px;
  }
</style>
